module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46053018-1","head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@walltowall/gatsby-theme-ww-base/gatsby-browser.js'),
      options: {"plugins":[],"root":"/opt/build/repo","siteTitle":"Orthopedic Associates","siteTitleShort":"OA","siteDescription":"For nearly 50 years, our team has put Hawai'i back in action—quickly, effectively, and with less pain.","siteUrl":"https://oahawaii.com","withNetlify":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000},
    }]
