// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-affiliates-js": () => import("./../../../src/pages/about/affiliates.js" /* webpackChunkName: "component---src-pages-about-affiliates-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-our-story-js": () => import("./../../../src/pages/about/our-story.js" /* webpackChunkName: "component---src-pages-about-our-story-js" */),
  "component---src-pages-for-patients-faqs-js": () => import("./../../../src/pages/for-patients/faqs.js" /* webpackChunkName: "component---src-pages-for-patients-faqs-js" */),
  "component---src-pages-for-patients-good-faith-estimate-js": () => import("./../../../src/pages/for-patients/good-faith-estimate.js" /* webpackChunkName: "component---src-pages-for-patients-good-faith-estimate-js" */),
  "component---src-pages-for-patients-index-js": () => import("./../../../src/pages/for-patients/index.js" /* webpackChunkName: "component---src-pages-for-patients-index-js" */),
  "component---src-pages-for-patients-insurance-js": () => import("./../../../src/pages/for-patients/insurance.js" /* webpackChunkName: "component---src-pages-for-patients-insurance-js" */),
  "component---src-pages-for-patients-no-surprises-js": () => import("./../../../src/pages/for-patients/no-surprises.js" /* webpackChunkName: "component---src-pages-for-patients-no-surprises-js" */),
  "component---src-pages-for-patients-resources-js": () => import("./../../../src/pages/for-patients/resources.js" /* webpackChunkName: "component---src-pages-for-patients-resources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-physician-js": () => import("./../../../src/templates/physician.js" /* webpackChunkName: "component---src-templates-physician-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

